<template>
  <div>
    <DxDataGrid
      ref="dataGridRef"
      :data-source="items"
      key-expr="id"
      :columns-auto-width="true"
      :selection="{ mode: 'multiple' }"
      :allow-column-resizing="true"
      :on-row-dbl-click="customLink ? getUrl : rowDblClick"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :column-hiding-enabled="columnHidingEnabled"
      @selection-changed="onSelectionChanged"
      @exporting="onExporting"
      @rowPrepared="onRowPrepared"
      @option-changed="handlePropertyChange"
    >

      <DxExport
        :enabled="true"
        :allow-export-selected-data="true"
      />

      <DxToolbar v-if="customTimeRange">
        <DxItem
          location="after"
          template="TimeRangeTemplate"
        />
        <DxItem
          name="exportButton"
          location="after"
        />
      </DxToolbar>

      <template #TimeRangeTemplate>
        <div class="widget-range">

          <b-form-select
            v-model="selectedRange"
            size="sm"
            :options="rangeOptions"
            @change="changeTimeItem"
          />

          <div
            v-if="loadTableDataProgress"
            class="justify-content-center mr-1"
          >
            <b-spinner label="Loading..." />
          </div>

        </div>
      </template>

      <DxFilterRow
        :visible="true"
      />

      <DxHeaderFilter :visable="true" />

      <!-- <DxPaging :page-size="pagesize" /> -->

      <DxPager
        :show-page-size-selector="false"
         display-mode="compact"
        :allowed-page-sizes="[5, 20, 50]"
      />

      <DxColumn
        v-for="column in columnsTemplate"
        :key="column.label"
        :caption="column.label"
        :data-field="column.data"
        :data-type="column.data_type ? column.data_type : 'string'"
        :visible="column.visible"
        :posix="column.position ? column.position : null"
        :allow-reordering="column.reaorder ? column.reaorder : true"
        :allow-filtering="column.filterable !== undefined ? column.filterable : true"
        :allow-sorting="column.sortable !== undefined ? column.sortable : true"
        :calculate-cell-value="column.calculateCellValue !== undefined ? column.calculateCellValue : (row) => row[column.data] !== undefined ? row[column.data] : ( row[column.firstnode] !== undefined && row[column.firstnode][column.secondnode] !== undefined ) ? row[column.firstnode][column.secondnode] : '-' "
        :filter-value="column.filterValue ? column.filterValue : null"
        :sort-order="column.sort ? column.sort : null"
      />

      <DxColumn
        v-for="customColumnTemplate in customColumnsTemplate"
        :key="customColumnTemplate.data"
        :caption="customColumnTemplate.label"
        :cell-template="customColumnTemplate.custom_template.template_name"
        :data-field="customColumnTemplate.data"
        :data-type="customColumnTemplate.data_type ? customColumnTemplate.data_type : 'string'"
        :visible="customColumnTemplate.visible"
        :posix="customColumnTemplate.position ? customColumnTemplate.position : null"
        :allow-reordering="customColumnTemplate.reaorder ? customColumnTemplate.reaorder : true"
        :allow-filtering="customColumnTemplate.filterable !== undefined ? customColumnTemplate.filterable : true"
        :allow-sorting="customColumnTemplate.sortable !== undefined ? customColumnTemplate.sortable : true"
        :calculate-cell-value="customColumnTemplate.calculateCellValue !== undefined ? customColumnTemplate.calculateCellValue : (row) => row[customColumnTemplate.data] !== undefined ? row[customColumnTemplate.data] : null"
        :filter-value="customColumnTemplate.filterValue ? customColumnTemplate.filterValue : null"
        :sort-order="customColumnTemplate.sort ? customColumnTemplate.sort : null"
      />

      <template
        v-for="customColumnTemplate in customColumnsTemplate"
        #[customColumnTemplate.custom_template.template_name]="{ data }"
      >
        <div :key="'key-'+customColumnTemplate.data">

          <span v-if="tableName == 'rides-list'">

            <span v-if="data.data[customColumnTemplate.data] && data.data[customColumnTemplate.data] != ''">
              <strong v-b-tooltip="customColumnTemplate.custom_template.options[data.data[customColumnTemplate.data].toLowerCase()].tooltip">
                <div
                  class="m-auto icon"
                  :class="customColumnTemplate.custom_template.options[data.data[customColumnTemplate.data].toLowerCase()].icon"
                />
              </strong>
            </span>

            <span v-else-if="tableName == 'rides-list' && !data.data[customColumnTemplate.data] || data.data[customColumnTemplate.data] == ''">
              <strong v-b-tooltip="customColumnTemplate.custom_template.options['react'].tooltip">
                <div
                  class="m-auto icon"
                  :class="customColumnTemplate.custom_template.options['react'].icon"
                />
              </strong>
            </span>

          </span>

          <span v-if="tableName == 'scooters-list' && data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'scooters.odometer'">
            <strong>
              <div class="m-auto">{{ data.data.odometer }}KM</div>
            </strong>
          </span>

          <span v-if="tableName == 'customers-list'">
            <span v-if="data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'customers.store'">
              <div>
                <b-badge
                  v-if="data.data.store === 'unknown'"
                  :variant="'primary'"
                >
                  Unknown
                </b-badge>
                <b-badge
                  v-else-if="data.data.store === 'usa'"
                  :variant="'secondary'"
                >
                  USA
                </b-badge>
                <b-badge
                  v-else-if="data.data.store === 'ca'"
                  :variant="'info'"
                >
                  Canada
                </b-badge>
                <b-badge
                  v-else-if="data.data.store === 'subs'"
                  :variant="'danger'"
                >
                  Subscription
                </b-badge>
                <b-badge
                  v-else-if="data.data.store === 'app'"
                  :variant="'warning'"
                >
                  App User
                </b-badge>
              </div>

            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'customers.app_user'">
              <div>

                <b-badge
                  v-if="data.data.is_app_user == true"
                  :variant="'success'"
                >
                  Yes
                </b-badge>

                <b-badge
                  v-else
                  :variant="'secondary'"
                >
                  No
                </b-badge>

              </div>

            </span>

          </span>

          <span v-if="tableName == 'city-customers-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'city.customers.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>
          </span>
          <span v-if="tableName == 'rides-incidents-list'">

            <span v-if="customColumnTemplate.custom_template.template_name == 'rides.incidents.model'">

              <div class="text-center">
                <router-link
                    v-b-tooltip="'View Ride'"
                    class="pointer-cursor"
                    :to="{ name: 'view-ride' , params: { id: data.data.ride_id }}"
                >
                  <b>{{ data.data.ride_id }}</b>
                </router-link>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'rides.incidents.created_at'">
              <div class="text-center">
                <div>
                  <b>{{ moment.utc(data.data.created_at * 1000).local().format('ll hh:mma') }}</b>
                </div>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'rides.incidents.status'">
              <div class="text-center">
                <div>
                  <b>{{ data.data.status.split('-').map(t => capitalizeFirstLetter(t)).join(' ') }}</b>
                </div>
              </div>
            </span>

          </span>

          <span v-if="tableName == 'admins-logs-list'">

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.model'">
              <div class="text-center">
                <router-link
                  v-if="data.data.type"
                  v-b-tooltip="'View '+capitalizeFirstLetter(data.data.type)"
                  class="pointer-cursor"
                  :to="{ name: routes[data.data.type] , params: { id: data.data.model_id }}"
                >
                  <b>{{ capitalizeFirstLetter(data.data.type) }}</b>
                </router-link>
                <div v-else>
                  -
                </div>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.created_at'">
              <div class="text-center">
                <div>
                  <b>{{ moment.utc(data.data.created_at * 1000).local().format('ll hh:mma') }}</b>
                </div>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>

          </span>

          <span v-if="tableName == 'targets-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'targets.list.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>
          </span>

          <span v-if="tableName == 'admins-users-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.users.list.actions'">
              <div class="text-center">

                <feather-icon
                  v-if="$ableTo('Edit Admin')"
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Change Admin Password')"
                  v-b-tooltip="'Send reset password email'"
                  icon="SendIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('resetPassword',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Deactivate Admin') && data.data.is_active"
                  v-b-tooltip="'Deactivate'"
                  icon="XIcon"
                  class="mr-50 pointer-cursor text-danger"
                  @click="() => $emit('toggle',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Activate Admin') && !data.data.is_active"
                  v-b-tooltip="'Activate'"
                  icon="PlayIcon"
                  class="mr-50 pointer-cursor text-success"
                  @click="() => $emit('toggle',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Delete Admin')"
                  v-b-tooltip="'Delete'"
                  icon="TrashIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('remove',data.data)"
                />

              </div>
            </span>
          </span>

          <span v-if="tableName == 'admins-roles-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'admin.roles.list.actions'">
              <div class="text-center">

                <feather-icon
                  v-if="$ableTo('Edit Role')"
                  v-b-tooltip="'Edit Role'"
                  icon="EditIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />

              </div>
            </span>
          </span>

          <span v-if="tableName == 'service-locator-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'servicelocators.list.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Delete service locators'"
                  icon="XIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('delete',data.data)"
                />
              </div>
            </span>
          </span>

          <span v-if="tableName == 'maintenance-backup-list'">

            <span v-if="customColumnTemplate.custom_template.template_name == 'maintenance.backup.list.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Download Backup'"
                  icon="DownloadIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('download',data.data.backup,getDate(data.data.backup)+'.zip')"
                />
                <feather-icon
                  v-b-tooltip="'Delete Backup'"
                  icon="XIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('remove',getDate(data.data.backup)+'.zip')"
                />
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'maintenance.backup.list.backup_date'">
              {{ getDate(data.data.backup) }}
            </span>

          </span>

          <span v-if="tableName == 'surveys-list'">

            <span v-if="customColumnTemplate.custom_template.template_name == 'surveys.status'">
              <b-badge
                v-if="data.data.status.toLowerCase() === 'active'"
                :variant="'light-success'"
              >
                Active
              </b-badge>
              <b-badge
                v-if="data.data.status.toLowerCase() === 'blocked'"
                :variant="'light-danger'"
              >
                Blocked
              </b-badge>
            </span>
          </span>

        </div>
      </template>

    </DxDataGrid>

  </div>

</template>
<script>

import {
  DxItem,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
  DxPaging,
  DxColumn,
  DxToolbar,
  DxPager,

} from 'devextreme-vue/data-grid'

import { VBTooltip } from 'bootstrap-vue'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import Moment from 'moment'

export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxPaging,
    DxColumn,
    DxItem,
    DxToolbar,
    DxPager,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    customTimeRange: {
      type: Boolean,
      default() {
        return false
      },
    },
    customColumns: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    routerLink: {
      type: String,
      default() {
        return ''
      },
    },
    tableName: {
      type: String,
      default() {
        return ''
      },
    },
    exportFileName: {
      type: String,
      default() {
        return `sheet-${Moment(new Date()).format('YYYY-MM-DD')}`
      },
    },
    dataSource: {
      type: Object,
      default() {
        return null
      },

    },
    customLink: {
      type: Boolean,
      default() {
        return false
      },
    },
    cutomFunction: {
      type: String,
      default() {
        return null
      },
    },
    store: {
      type: String,
      default() {
        return null
      },
    },
    primaryKey: {
      type: Boolean,
      default() {
        return true
      },
    },

    loading: {
      type: Boolean,
      default() {
        return true
      },
    },
    routes: {
      type: Object,
      default() {
        return null
      },
    },

    pagesize: {
      type: Number,
      default() {
        return 20
      },
    },

    selectedTime: {
      type: String,
      default() {
        return null
      },

    },

  },
  data() {
    return {
      selectedRange: '',
      loadTableDataProgress: false,
      rangeOptions: [
        {
          text: 'All time',
          value: null,
        },
        {
          text: Moment().year(),
          value: Moment().year(),
        },
        {
          text: Moment().subtract(1, 'year').year(),
          value: Moment().subtract(1, 'year').year(),
        },
        {
          text: Moment().subtract(2, 'year').year(),
          value: Moment().subtract(2, 'year').year(),
        },
      ],
      moment: Moment,
      selectedRows: [],
      customColumnsTemplate: [],
      columnsTemplate: [],
      originalColumns: [],
      columnHidingEnabled: false,
    }
  },
  watch: {
    loading(newValue) {
      this.loadTableDataProgress = newValue
    },
  },
  mounted() {
    this.originalColumns = this.columns
    this.customColumnsTemplate = this.columns.filter(column => column.custom_template && column.custom_template.template_name.trim() !== '')
    this.columnsTemplate = this.columns.filter(column => !column.custom_template || column.custom_template.template_name.trim() === '')
    this.columnHidingEnabled = window.innerWidth < 1000
    window.addEventListener('resize', this.enableDisableHiding)
    this.selectedRange = null
  },
  methods: {
    changeTimeItem() {
      this.loadTableDataProgress = true
      this.$emit('loadTableData', this.selectedRange)
    },
    enableDisableHiding() {
      this.columnHidingEnabled = window.innerWidth < 1000
    },
    handlePropertyChange(event) {
      let index = event.fullName.match(/\d/g)
      if (index && index.length > 0) {
        if (event.value) {
          index = index.join('')
          if (index) {
            const ArrayOfSearch = [index, event.value]
            this.$emit('filter', ArrayOfSearch)
          }
        } else {
          this.$emit('filter', [index, null])
        }
      }
    },
    getDate(url) {
      const urlArr = url.split('/')
      const lastElement = urlArr[urlArr.length - 1].replace('.zip', '')
      return lastElement
    },
    getUrl(row) {
      const id = row.data.uuid
      // eslint-disable-next-line default-case
      switch (this.store) {
        case 'subs':
          window.open(`https://rideapollo.myshopify.com/admin/orders/${id}`)
          break
        case 'usa':
          window.open(`https://apollo-mvmt.myshopify.com/admin/orders/${id}`)
          break
        case 'ca':
          window.open(`https://apollo-scooters-canada.myshopify.com/admin/orders/${id}`)
          break
      }
    },

    onSelectionChanged({ selectedRowsData }) {
      if (this.primaryKey) {
        this.selectedRows = selectedRowsData.map(selectedRow => selectedRow.id)
      }
    },
    searchColumns(event) {
      console.log(event)
    },
    rowDblClick(row) {
      if (row.data.id === undefined || row.data.id === '' || this.routerLink === '') {
        return false
      }
      if (row.link) {
        window.open(row.data.link, '_blank')
      } else {
        this.$router.push({ name: this.routerLink, params: { id: row.data.id } })
      }
      return false
    },
    onRowPrepared: function(e) {
      if(e.data?.scooter_type == 'Total Average'){
        e.rowElement.classList.add('selected_row');
      }
    },
    onExporting(e) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet(this.exportFileName)
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'device') {
              excelCell.value = excelCell.value ? excelCell.value : 'React'
            }
          }
        },

      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.exportFileName}.xlsx`)
        })
      })
      e.cancel = true
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

  },
}

</script>

  <style lang="scss">
       .dx-datagrid-content .dx-datagrid-table .dx-row > td{
          vertical-align: middle !important;
        }

        .pointer-cursor {
          cursor:pointer;
        }

    .widget-range {
      display: flex !important;
      flex-direction: row-reverse;
      align-items: center;
      .dropdown  {
        border: #C6C6C6 1px solid !important;
        margin-top: 1px !important;
        padding-top: 8px;
        border-radius: 8px;
        width: 80% !important;
        float: right !important;
        .dropdown-toggle {
          border-radius: 8px;
          width: 100% !important;
          width: 120px !important;
          color: #1D1D1D !important;
          margin-top: -8px !important;
          margin-bottom: -5px;
        }
      }

    }
    .caret-symbol {
      display: block;
      height: 20px;
      width: 20px;
      float: right;
      margin-right: 10px;
      background-image: url('~@/assets/images/icons/general-icons/caret.svg');
    }

    .selected_row {
      background-color: #E7E2E0;
      font-weight: bold;
    }
  </style>
